import React from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { EffectFade, Autoplay } from 'swiper';

import { CardMedia } from "@mui/material";
import { styled } from "@mui/material/styles";

import 'swiper/css';
import 'swiper/css/effect-fade';

const SwiperSlideImage = styled(CardMedia)`
 height: calc(100vh - 160px);
  ${props => props.theme.breakpoints.up("sm")} {
    height: 540px;
  }
`


const SlickSlider: React.FC<{ items: string[]}> = ({ items}) => {
  return (
    <Swiper
      centeredSlides={true}
      autoplay={{
        delay: 2500,
        disableOnInteraction: false,
      }}
      modules={[EffectFade, Autoplay]}
      effect="fade"
    >
      {items.map(item =>
        <SwiperSlide key={item}>
          <SwiperSlideImage image={item}/>
        </SwiperSlide>
      )}
    </Swiper>
  );
}

export default SlickSlider;
