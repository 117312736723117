import React from "react";

import { Typography, useMediaQuery, Box } from "@mui/material";

import SlickSlider from 'components/SlickSlider'
import AnimateText from 'components/TextTransition/AnimateText'
import Seo from "components/Seo";

import { useSiteMetadata } from "common/hooks";

const web_images = [
  'https://res.cloudinary.com/adventurersports/image/upload/v1493635275/gallery/home/bg4.jpg',
  'https://res.cloudinary.com/adventurersports/image/upload/v1493576785/gallery/home/bg2.jpg',
  'https://res.cloudinary.com/adventurersports/image/upload/v1493631169/gallery/home/bg3.jpg'
];

const mobile_images = [
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g1.png',
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g2.png',
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g3.png',
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g4.png',
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g5.png',
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g6.png',
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g7.png',
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g8.png',
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g9.png',
  'https://res.cloudinary.com/adventurersports/image/upload/v1655987139/gallery/home/g10.png',
];

const IndexPage = () => {
  const { title } = useSiteMetadata();
  const matches = useMediaQuery('(min-width:600px)');
  return (
    <>
      <Seo title="Acasă" />
      <Typography variant="h5" component="h1" gutterBottom sx={{ display: 'none'}}>
        {title}
      </Typography>

      <Box sx={{position: 'relative'}}>
        <AnimateText />
      </Box>
      <SlickSlider items={ matches ? web_images : mobile_images}/>
    </>
  )
}

export default IndexPage
