import React, { useEffect } from "react";
import TextTransition from "./index";
import {styled} from "@mui/material";

const AnimMain = styled('div')(() => ({
  position: 'absolute',
  zIndex: 9,
}))

const AnimateText = () => {

    useEffect(() => {
      const interval = setInterval(() => {
        console.log('This will run every 5 second!');
      }, 5000);
      return () => clearInterval(interval);
    }, []);

    return (
        <AnimMain>
          <TextTransition
            effectIn
            variant="h4"
            text="Bine aţi venit pe site-ul de prezentare a firmei "
          />
        </AnimMain>
    );
  };

export default AnimateText;
